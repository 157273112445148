import { Layer, Notification, Spinner, Text } from 'grommet';
import React from 'react';

interface ToastProps {
  status: 'unknown' | 'normal' | 'warning' | 'critical';
  message: string;
  isVisible: boolean;
  title: string;
}

function useToast() {
  const [toastState, toastify] = React.useState<ToastProps>({
    status: 'unknown',
    message: '',
    isVisible: false,
    title: ''
  });

  const renderToast = () =>
    toastState.isVisible ? (
      <Notification
        title={toastState.title}
        message={toastState.message}
        toast={{ position: 'top-right' }}
        onClose={() => toastify((prev) => ({ ...prev, isVisible: false }))}
      />
    ) : null;

  const renderLoader = (isLoading: boolean, loadingInfo: string) =>
    isLoading ? (
      <Layer
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          maxWidth: '300px',
          height: '200px'
        }}
      >
        <Spinner />
        <Text>{loadingInfo}</Text>
      </Layer>
    ) : null;

  const toast = {
    success: (message: string, title: string = 'Operation was Succesfull') =>
      toastify({ isVisible: true, status: 'critical', title, message }),
    error: (message: string, title: string = 'An Error Occured') =>
      toastify({ isVisible: true, status: 'critical', title, message })
  };
  return { renderToast, toastify, toast, renderLoader };
}

useToast.propTypes = {};

export default useToast;
