import React from 'react';
import tw from 'twin.macro';
import { AvatarPlaceHolder } from 'components/icons';
import { Button } from 'tutadoo-design-system/src/components/Button';
import { Avatar } from 'tutadoo-design-system/src/components/Avatar';
import Input from 'components/InputControl';
import { Box, Form, FormExtendedEvent, Spinner, Text } from 'grommet';
import { IUser, UserService } from 'services/users';
import { useMutation } from '@apollo/client';
import useToast from '../hooks/useToast';
import useHiddenUploadInput from '../hooks/useHiddenUploadInput';

const Divider = tw.div`w-full mx-auto h-0 my-6`;

type userInfo = {
  first_name: string;
  last_name: string;
  image: string;
};

function UserAccountForm({ user }: { user: IUser; userId: string }) {
  const { toast, renderLoader, renderToast } = useToast();
  const [newImage, setNewImage] = React.useState({ url: user?.image });

  const {
    loading: loadingImage,
    renderInput,
    inputRef
  } = useHiddenUploadInput({
    id: 'fileInp',
    onChange: (img) => setNewImage({ url: img })
  });

  const [formValue, setFormValue] = React.useState<userInfo>({
    first_name: user.first_name,
    last_name: user?.last_name,
    image: ''
  });

  const [updateUser, { loading }] = useMutation<{ user: IUser }, { set: IUser }>(
    UserService.updateUserInfo,
    {
      onCompleted: () => toast.success('Account Info Updated'),
      onError: (err) => toast.error(err?.message)
    }
  );

  const handleSubmit = (event: FormExtendedEvent<IUser, Element>) => {
    event.value.image = newImage?.url || user.image;
    updateUser({ variables: { set: event.value } });
  };

  return (
    <Form value={formValue} onChange={setFormValue} onSubmit={handleSubmit}>
      <Text className="fontF-semibold mt-6 inline-block mb-2" size="medium">
        Account image
      </Text>

      <p className="text-sm text-primary block">JPG, or PNG. Required size 2MB or less.</p>
      <div className="flex items-center mt-6">
        <div className="image_box relative">
          {newImage?.url ? (
            <Avatar size="xlarge" rounded={true} image={newImage?.url} />
          ) : (
            <AvatarPlaceHolder />
          )}
          {loadingImage && (
            <Box className="absolute flex items-center justify-center opacity-70 inset-0 bg-gray-900">
              <Spinner color={'#fff'} />
            </Box>
          )}
        </div>
        <Button
          onClick={() => inputRef?.current.click()}
          primary
          margin={'small'}
          size="medium"
          label="Upload image"
        />
        <Button onClick={() => setNewImage({ url: '' })} secondary size="small" label="Delete" />
        {renderInput()}
      </div>
      <Divider style={{ border: '1px solid #EBECED' }} />
      <Text className="font-semibold mt-6 inline-block mb-2" size="medium">
        Account Details
      </Text>
      <Box>
        <Input disabled label="email" inputProps={{ readOnly:true, value:user?.email }} />
      </Box>
      <div className="flex w-full ">
        <Box width={'50%'} className="mr-4">
          <Input name="first_name" label="First name" />
        </Box>
        <Box width={'50%'}>
          <Input name="last_name" label="Last name" />
        </Box>
      </div>
      <Button type="submit" primary margin={'small'} size="medium" label="Save changes" />
      {renderLoader(loading, 'Updating User Info')}
      {renderToast()}
    </Form>
  );
}

export default UserAccountForm;
