import React from 'react';
import { ChangeEvent } from 'react';
import { UploadApi } from 'services/api/upload-api';
import { useApi } from 'providers/ApiProvider';

type HiddenUploadInputProps = {
  multiple?: boolean;
  accept?: string;
  id: string;
  onChange: (url: string) => void;
};

function useHiddenUploadInput(props: HiddenUploadInputProps) {
  const { accept = '.jpeg,.jpg,.png,.pdf', multiple = false, id, onChange } = props || {};
  const { api } = useApi();
  const uploadApi = new UploadApi(api);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const inputRef = React.useRef<HTMLInputElement>(null);

  async function handleChange(e: ChangeEvent<HTMLInputElement>) {
    try {
      e.stopPropagation();
      setLoading(true);
      const files = e.target.files;
      if (!files[0]) return;
      const result = (await uploadApi.upload(files[0])) as any;
      onChange(result?.result?.data);
      if (result?.kind !== 'ok') setError(result?.kind);
      setLoading(false);
    } catch (err) {}
  }

  const renderInput = () => (
    <input
      style={{ visibility: 'hidden', width: '0px', height: '0px', padding: '0px', opacity: '0' }}
      type="file"
      multiple={multiple}
      hidden={true}
      id={id}
      accept={accept}
      onChange={handleChange}
      ref={inputRef}
    />
  );

  return {
    renderInput,
    loading,
    error,
    inputRef
  };
}

export default useHiddenUploadInput;
