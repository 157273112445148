import React, { ChangeEvent } from 'react';

import { FormField, FormFieldProps, TextInput, TextInputProps } from 'grommet';
export interface InputProps extends FormFieldProps {
  inputProps?: TextInputProps;
  width?: string;
  className?: string;
  icon?: JSX.Element;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const InputControl: React.FC<InputProps> = ({ label, name, error, icon, inputProps, ...props }) => {
  return (
    <FormField label={label} name={name} error={error} {...props}>
      <TextInput icon={icon} name={name} {...inputProps} />
    </FormField>
  );
};

export default InputControl;
