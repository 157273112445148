import { useAuth0 } from '@auth0/auth0-react';
import UserAccountForm from 'components/UserAccountForm';
import UserDashboard from 'components/DashboardLayout/UserDashboard';
import { Box, Heading, Spinner, Text } from 'grommet';
import React from 'react';
import { Button } from 'tutadoo-design-system/src/components/Button';
import tw from 'twin.macro';
import { useMutation, useQuery } from '@apollo/client';
import { UserService, IUser } from 'services/users';
import useToast from '../../hooks/useToast';

const Container = tw.div`max-w-[864px] mx-auto mt-7`;
const Divider = tw.div`w-full mx-auto h-0 my-6`;

function settings() {
  const { renderLoader, renderToast, toast } = useToast();
  const { user, isLoading } = useAuth0();
  const { data, loading } = useQuery<{ userInfo: IUser }>(UserService.getUserInfo(user?.sub));
  const [requestPasswordChange, { loading: requesting }] = useMutation(
    UserService.initiatPasswordChange(user?.email),
    {
      onCompleted: () => toast.success("We've just sent you an email to reset your password."),
      onError: (err) => toast.error(err?.message)
    }
  );

  return (
    <UserDashboard>
      <Container>
        <Heading level={'3'} size="medium">
          Account Settings
        </Heading>
        {isLoading || loading ? (
          <Box height={{ min: '200px' }} justify="center" align="center">
            <Spinner />
            <Text textAlign="center">Fetching Form Info</Text>
          </Box>
        ) : (
          <UserAccountForm
            userId={user?.sub}
            user={{
              first_name: data?.userInfo?.first_name,
              last_name: data?.userInfo?.last_name,
              image: data?.userInfo?.image || user?.picture,
              email: user?.email
            }}
          />
        )}

        <Divider style={{ border: '1px solid #EBECED' }} />
        {/* <div className="flex w-full ">
          <Box width={'50%'} className="mr-4">
            <Input placeholder="Current password" label="Current password" />
          </Box>
          <Box width={'50%'}>
            <Input placeholder="New password" label="New password" />
          </Box>
        </div> */}
        <Heading level={'4'} size="medium">
          Change Password
        </Heading>
        <Text>
          On initiating Password Reset, you will be sent further steps via your registered email
        </Text>
        <Button
          onClick={() => requestPasswordChange()}
          primary
          margin={'small'}
          size="medium"
          label="Click To Change Passowrd"
        />
      </Container>
      {renderLoader(requesting, 'Initiating Request')}
      {renderToast()}
    </UserDashboard>
  );
}

settings.propTypes = {};

export default settings;
