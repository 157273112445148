import React, { SVGProps } from 'react';

const AvatarPlaceHolder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="112"
    height="112"
    viewBox="0 0 112 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 6C0 2.68629 2.68629 0 6 0H106C109.314 0 112 2.68629 112 6V106C112 109.314 109.314 112 106 112H6C2.68629 112 0 109.314 0 106V6Z"
      fill="#F5F5F6"
    />
    <path
      d="M56 56C58.21 56 60 54.21 60 52C60 49.79 58.21 48 56 48C53.79 48 52 49.79 52 52C52 54.21 53.79 56 56 56ZM56 58C53.33 58 48 59.34 48 62V63C48 63.55 48.45 64 49 64H63C63.55 64 64 63.55 64 63V62C64 59.34 58.67 58 56 58Z"
      fill="#9D9FA4"
    />
  </svg>
);

export default AvatarPlaceHolder;
