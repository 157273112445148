import { UploadResult } from '.';
import { Api } from './api';
import { getGeneralApiProblem } from './api-problem';

export class UploadApi {
  private api: Api;

  constructor(api: Api /*token?: string*/) {
    this.api = api;
    // if (token) this.api.apisauce.setHeader('authorization', `Bearer ${token}`);
  }

  /**
   * Upload a file
   */
  upload(file: File): Promise<UploadResult> {
    const formData = new FormData();
    formData.append('File', file);
    return this.api.apisauce
      .post(`${process.env.GATSBY_TUTADOO_NET || 'https://dev.tutadoo.com'}/api/file`, formData)
      .then((response) => {
        if (!response.ok) {
          const problem = getGeneralApiProblem(response);
          if (problem) return problem;
        }
        if (response.data.errors) {
          return { kind: 'bad-data', data: response.data.errors };
        }
        const result = response.data;
        return {
          kind: 'ok',
          result
        };
      });
  }
}
