import { DocumentNode, gql } from '@apollo/client';

export interface IUserService {
  updateUserInfo: DocumentNode;
  getUserInfo: (userId: string) => DocumentNode;
  initiatPasswordChange: (userEmail: string) => DocumentNode;
}

var UserService: IUserService;

UserService = class {
  static getUserInfo = (userId: string) => gql`
  query UserInfo {
    userInfo:users_by_pk(id:"${userId}"){
      first_name
      last_name
      image
    }
  }`;

  static updateUserInfo = gql`
    mutation upsert_user_info($set:users_insert_input!) {
      insert_users(
        objects: [$set]
        on_conflict: { constraint: users_pkey, update_columns: [first_name, last_name, image] }
      ) {
        returning {
          id
          first_name
          last_name
          image
        }
      }
    }
  `;

  static initiatPasswordChange = (userEmail) => gql`
    mutation {
     changePassword(email:"${userEmail}")
    }
  `;
};

export default UserService;
